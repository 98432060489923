// Packages
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CheckCircleIcon, EmojiHappyIcon, ClockIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';

// Types
import type { SiteMetadata, Location } from '~types/Gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

// Components
import Blurb from '~components/common/Blurb';
import Container from '~components/common/Container';
import Layout from '~components/layouts/Default';
import Divider from '~components/sections/Divider';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';
import Button from '~components/common/Button';
import Grid from '~components/common/Grid';
import Input from '~components/forms/elements/Input';
import ImageVideo from '~components/common/ImageVideo';
import SubTitle from '~components/typography/Subtitle';
import Ul from '~components/typography/Ul';

interface DataProps {
  site: {
    siteMetadata: SiteMetadata;
  };
  imageVideoCover: IGatsbyImageData;
  imageLogo: IGatsbyImageData;
  imageMeditation: IGatsbyImageData;
}

const ELOPAGEURL = 'https://elopage.com/s/entspannungshelden/kopie-von-upgrade-your-mind-gc-1/payment';

export default function Page({ data, location }: { data: DataProps; location: Location }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mail: '',
    street: '',
    streetNumber: '',
    plz: '',
    city: ''
  });
  const handleEloPage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { firstName, lastName, mail, street, streetNumber, plz, city } = formData;
    window.open(`${ELOPAGEURL}?first_name=${firstName}&last_name=${lastName}&email=${mail}&street=${street}&street_number=${streetNumber}&zip=${plz}&city=${city}`);
  };

  const imageLogo = getImage(data.imageLogo);

  return (
    <Layout location={location} title="Mentoring" desc="" active="Mentoring" minimalFooter dividerFooter>
      <div className={`overflow-hidden bg-brand-green/50`}>
        <div>
          <Container>
            <Grid cols={2} items="center">
              <div>
                <div className="flex items-center space-x-3">
                  <span className="inline-block rounded-full bg-brand-gray px-3 py-0.5 text-sm font-semibold text-white">Online Coaching</span>
                  <div className="inline-flex">
                    {[...Array(5)].map(() => {
                      return <StarIcon className="inline-block h-4 w-4 text-yellow-400" />;
                    })}
                  </div>
                </div>

                <h1 className="mb-5 mt-5 font-display text-2xl font-bold sm:text-2xl md:text-3xl lg:text-4xl">
                  Erkenne und verändere deine negative Angewohnheiten und tausche Erschöpfung gegen Leichtigkeit, ohne dein Leben komplett ändern zu müssen.
                </h1>
                <P className="text-base sm:text-xl lg:text-lg xl:text-xl">
                  Du bist erschöpft, müde, ausgelaugt? Vermisst die Lebensfreude und Leichtigkeit in deinem Leben? Dann ist Upgrade your Mind für dich wie gemacht. In diesem Gruppencoaching wirst du vom Stressexperten Johannes begleitet und
                  dabei unterstützt, deine Energie zurückzugewinnen.
                </P>
              </div>
              <div>
                <ImageVideo
                  image={data.imageVideoCover}
                  video="https://res.cloudinary.com/lautenschlager/video/upload/v1662446988/kunden/entspannungshelden/upgrade-your-mind-compressed_v4mot3.mp4"
                  alt="Upgrade your Mind - Videoerklärung"
                />
              </div>
            </Grid>
          </Container>
        </div>
        <Divider className={`rotate-180 text-gray-50`} />
      </div>
      <Container width="narrow">
        <Grid cols={2}>
          <div className="relative mb-8 md:order-2 md:-mt-48 md:mb-0">
            <div className="rounded-xl bg-gray-900 p-6 text-center text-white">
              <H2 className="mb-3">Hier teilnehmen</H2>
              <P className="mb-5" textColor="text-gray-200">
                Melde Dich an und sei bei Upgrade your Mind dabei
              </P>
              <form className="space-y-4" onSubmit={(e) => handleEloPage(e)}>
                <Input
                  id="firstname"
                  placeholder="Max"
                  autoComplete="given-name"
                  name="Vorname"
                  type="text"
                  label="Vorname"
                  value={formData.firstName}
                  onChange={(e) => setFormData((prev) => ({ ...prev, firstName: e.target.value }))}
                  white
                  required
                />
                <Input
                  id="lastname"
                  placeholder="Mustermann"
                  autoComplete="family-name"
                  name="Nachname"
                  type="text"
                  label="Nachname"
                  value={formData.lastName}
                  onChange={(e) => setFormData((prev) => ({ ...prev, lastName: e.target.value }))}
                  white
                  required
                />
                <Input
                  id="mail"
                  placeholder="mustermann@beispiel.de"
                  autoComplete="email"
                  name="E-Mail"
                  type="email"
                  label="E-Mail"
                  value={formData.mail}
                  onChange={(e) => setFormData((prev) => ({ ...prev, mail: e.target.value }))}
                  white
                  required
                />
                <div className="grid grid-cols-12 gap-2">
                  <Input
                    id="address"
                    placeholder="Musterstraße"
                    autoComplete="address-line1"
                    name="Straße"
                    type="text"
                    label="Straße"
                    value={formData.street}
                    onChange={(e) => setFormData((prev) => ({ ...prev, street: e.target.value }))}
                    white
                    classNameContainer="col-span-8 md:col-span-9"
                    required
                  />
                  <Input
                    id="adress-number"
                    placeholder="12"
                    name="Hausnummer"
                    type="text"
                    label="Nr."
                    value={formData.streetNumber}
                    onChange={(e) => setFormData((prev) => ({ ...prev, streetNumber: e.target.value }))}
                    white
                    classNameContainer="col-span-4 md:col-span-3"
                    required
                  />
                </div>
                <div className="grid grid-cols-12 gap-2">
                  <Input
                    id="plz"
                    placeholder="00000"
                    autoComplete="postal-code"
                    name="PLZ"
                    type="text"
                    label="PLZ"
                    value={formData.plz}
                    onChange={(e) => setFormData((prev) => ({ ...prev, plz: e.target.value }))}
                    classNameContainer="col-span-5 md:col-span-4"
                    white
                    required
                  />
                  <Input
                    id="city"
                    placeholder="Musterstadt"
                    autoComplete="address-level2"
                    name="Stadt"
                    type="text"
                    label="Stadt"
                    value={formData.city}
                    onChange={(e) => setFormData((prev) => ({ ...prev, city: e.target.value }))}
                    white
                    classNameContainer="col-span-7 md:col-span-8"
                    required
                  />
                </div>
                <div className="pt-3">
                  <Button submit text="Weiter" className="mt-3 w-full" />
                </div>
              </form>
            </div>
          </div>
          <dl className="space-y-6 md:order-1">
            <Blurb name="Erfolg und Widerstandsfähigkeit" description="Fördere durch Achtsamkeit deinen beruflichen Erfolg und deine Widerstandsfähigkeit." icon={<CheckCircleIcon className="h-8 w-8" />} align="left" iconAlign="left" />
            <Blurb name="Mehr Zeit" description="Du trainierst das Nein-sagen und Grenzen setzen und gewinnst so mehr Zeit für dich." icon={<ClockIcon className="h-8 w-8" />} align="left" iconAlign="left" />
            <Blurb name="Mehr Lebensfreude" description="Du lernst auf deine Bedürfnisse zu achten und erhältst mehr Lebensfreude." icon={<EmojiHappyIcon className="h-8 w-8" />} align="left" iconAlign="left" />
          </dl>
        </Grid>
      </Container>

      <Container>
        <Grid gap="gap-8 md:gap-16" cols={3}>
          <div>
            <div className="inline-flex">
              {[...Array(5)].map(() => {
                return <StarIcon className="inline-block h-4 w-4 text-yellow-400" />;
              })}
            </div>
            <p className="italic">
              Meine Situation vor dem Coaching war prekär. Ich hatte nicht nur mit den gesundheitlichen Folgen eines Burnouts zu kämpfen sondern musste auch meine Arbeitssituation völlig neu ausgestalten. Insbesondere das Einzelcoaching und
              der „kurze Dienstweg“ hat es mir einfach gemacht, an meinen Themen zu arbeiten. Der Videokurs hat mich in die Umsetzung gebracht und es mir ermöglicht mehr auf mich selbst zu achten.
            </p>
          </div>
          <div>
            <div className="inline-flex">
              {[...Array(5)].map(() => {
                return <StarIcon className="inline-block h-4 w-4 text-yellow-400" />;
              })}
            </div>
            <p className="italic">
              Ich habe mich für Upgrade your Mind entschieden, weil ich Johannes bereits seit 13 Jahren kenne und als Freund begleite. Er hat mich in einer schweren Krise mit sämtlichen Tools unterstützt die er in Upgrade your Mind in einen
              Videokurs gesteckt hat. Das Programm durfte ich also als Einzelcoaching nutzen und kann nun 2,5 Jahre später nur noch DANKE sagen.
            </p>
          </div>
          <div>
            <div className="inline-flex">
              {[...Array(5)].map(() => {
                return <StarIcon className="inline-block h-4 w-4 text-yellow-400" />;
              })}
            </div>
            <p className="italic">
              Meine Situation vor dem Mentoring empfand ich als zermürbend. Jeden Tag hatte ich mit negativen Glaubenssätzen zu kämpfen, die mir den Umgang mit mir selbst und mit anderen Menschen erschwert haben, was sich in der Folge auf
              meine seelische und körperliche Gesundheit ausgewirkt hat. Upgrade Your Mind hat mich dabei unterstützt zu verstehen und in die Annahme zu kommen. Mit dem Videokurs und gezielten Übungen konnte in die Umsetzung gehen und sorge
              nun besser für mich. Ich bin wirklich dankbar für die positive Veränderung in meinem Leben.
            </p>
          </div>
          <div>
            <div className="inline-flex">
              {[...Array(5)].map(() => {
                return <StarIcon className="inline-block h-4 w-4 text-yellow-400" />;
              })}
            </div>
            <p className="italic">
              Ach du Scheiße! ich hatte gerade so ein krasses mentales Erlebnis mit multiplen Erkenntnissen. Ich glaube so langsam verdrahtet sich was neues bei mir im Gehirn. Ich habe gerade erlebt, was ich nur theoretisch aus deinen
              Videos kannte, mit den Trampelpfaden im Gehirn. Es war ein komplettes Durcheinander im Kopf, aber es hat Sinn gemacht 😂. Du glaubst es nicht.. Also du bist gerade live dabei, welche Veränderung du und UYM bei mir bewirkt. 🤣
            </p>
          </div>
        </Grid>
      </Container>

      <Container>
        <div className="mx-auto mb-8 max-w-2xl text-center">
          <SubTitle className="mb-2">Das erwartet Dich</SubTitle>
          <H2>Die Module</H2>
        </div>

        <Grid cols={3}>
          {[
            {
              title: 'Ziele und Wünsche',
              content: ['Commitment und Selfcare Vertrag', 'Analyse der eigenen IST-SOLL Diskrepanz', 'Festlegen der Coachingziele', 'Das Retikuläre Aktivierungssystem', 'Quicktipps']
            },

            {
              title: 'Gewohnheiten',
              content: ['Wie Gewohnheiten entstehen', 'Die vier Bestandteile der Gewohnheit', 'Übung: Gewohnheiten erkennen', 'Gewohntes Denken, Fühlen, Handeln', 'Meditation ']
            },

            {
              title: 'Äußere Ordnung',
              content: ['Kleidung und Klamotten', 'Bücher und Zeitschriften', 'Dokumente digitalisieren', 'Die Schublade des Grauens', 'Erinnerungsstücke']
            },

            {
              title: 'Innere Ordnung  ',
              content: ['Wahrnehmung und Erleben', 'Glaubensssätze + Meditation', 'Stress und Wahrnehmung', 'Selbstfürsorge und Bedürfnisse', 'Annehmen und Loslassen']
            },
            {
              title: 'Individuelle Themen',
              content: ['Einzelcoachingeinheit', 'Individuelles Erarbeiten ', 'Umsetzung der Gewohnheiten', 'Kurzzeittrainings und Challenges', 'Open door meeting']
            },
            {
              title: 'Grenzen setzen',
              content: ['Werte und Leitbild', 'Eigene Grenzen erkennen', 'Achten der Bedürfnisse', 'Nein-Sagen lernen und trainieren', 'Übertrag in den Alltag']
            }
          ].map((module, i) => (
            <div key={i}>
              <div className="overflow-hidden rounded-xl border border-gray-300 bg-white">
                <div className="border-b border-gray-300 bg-white px-4 py-5 sm:px-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Woche {i + 1}</h3>
                  <p className="mt-1 text-sm text-gray-500">{module.title}</p>
                </div>
                <ul role="list" className="divide-y divide-gray-200">
                  {module.content.map((content, j) => (
                    <li>
                      <div className="px-4 py-4 sm:px-6">
                        <div className="flex items-center text-sm text-gray-500">
                          <CheckCircleIcon className="mr-3 h-4 w-4" />
                          {content}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </Grid>
      </Container>

      <div>
        <Container width="narrow">
          <Grid cols={2}>
            <div>{imageLogo ? <GatsbyImage image={imageLogo} alt="Upgrade your Mind Logo" /> : null}</div>
            <div>
              <SubTitle className="mb-2">Das erwartet Dich</SubTitle>
              <H2 className="mb-4">Das Mentoring</H2>
              <P className="mb-3">
                Wer die Theorie der Praxis vorzieht, der isst auch Speisekarten. Daher ist UYM ein Umsetzungskurs der dich fordert und fördert. Die Module setzen sich aus den Themenbereichen: Beruf und Finanzen, Freunde und Familie,
                Gesundheit und Sinn zusammen und ermöglichen es dir Erschöpfung gegen Leichtigkeit zu tauschen.
              </P>
              <Ul
                items={[
                  'Dein Umsetzungserfolg wird durch die gemeinsamen Gruppencoachings und OpenDoor Sessions möglich. So bist du stets im Austausch und in der direkten Kommunikation mit Johannes und den anderen Teilnehmer:innen.',
                  'Unter der Woche bewegst du dich flexibel durchs Proramm. Die sechs Module sind als 40-teiliger Videokurs hinterlegt und ermöglichen dir ein Orts- und Zeitunabhängiges Arbeiten, ganz so wie es für dich in deinen Alltag passt.',
                  'Bei individuellen Herausforderungen hast du einen direkten Draht zu Johannes per WhatsApp und erhältst binnen von 12 Stunden Unterstützung. Es erwartet dich außerdem eine Intensive Woche, in der du über ein Einzelcoaching an deinen individuellen Themen arbeitest.'
                ]}
                pretty
              />
            </div>
          </Grid>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    imageVideoCover: file(relativePath: { eq: "video/upgrade-your-mind-cover.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    imageLogo: file(relativePath: { eq: "mentoring/logo.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    imageMeditation: file(relativePath: { eq: "course-images/autogenes-training.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
